<script setup>
import { removeNonDigits, slugify } from "@/utilites/helpers";
import CartDropDown from "@/components/cart/CartDropDown.vue";
</script>

<template>
  <div v-if="isSearch" class="search-overly"></div>
  <header id="main-header" :class="{'home-header': $route.name === 'home'}">
    <div class="header container">
      <button @click="openMenu" class="menu-btn" aria-label="Open navigation menu">
        <img src="@/assets/images/menu.svg" alt="Navigation menu icon"/>
      </button>
      <nav class="menu" aria-label="Main menu">
        <div class="drawer">
          <nav role="navigation" aria-label="Main navigation">
            <ul role="menu">
              <li v-for="item in filteredHeaderNav" :key="item.id" role="none">
                <a 
                  :href="item.url" 
                  :target="item.new_tab ? '_blank' : '_self'"
                  :class="{ active: isRouteActive(item.url) }"
                  role="menuitem"
                >
                  {{ item.label.toUpperCase() }}
                </a>
              </li>
            </ul>
          </nav>
          <div
            v-if="menuVisible"
            class="menu drawer-visible"
            style="overflow: auto"
            aria-labelledby="drawer-menu-header"
          >
            <div class="drawer-header" id="drawer-menu-header">
              <button v-if="menuVisible" @click="closeMenu" class="close-btn" aria-label="Close menu">
                <img src="@/assets/images/close.svg" alt="Close menu icon"/>
              </button>
              <div class="logo">
                <a :href="'/'">
                  <img :src="settings.header_logo" alt="PreGame logo"/>
                </a>
              </div>
            </div>
            <nav role="navigation" aria-label="Drawer menu">
              <ul role="menu">
                <li v-for="item in filteredHeaderNav" :key="item.id" role="none">
                  <a 
                    :href="item.url" 
                    :target="item.new_tab ? '_blank' : '_self'"
                    :class="{ active: isRouteActive(item.url) }"
                    role="menuitem"
                    aria-haspopup="true"
                  >
                    {{ item.label.toUpperCase() }}
                  </a>
                </li>
                <li v-if="$isVisible == 1" role="none">
                  <a v-if="!isLoggedIn" :href="'/login'" role="menuitem">LOG IN</a>
                </li>
                <li v-if="$isVisible == 1" role="none">
                  <a v-if="!isLoggedIn" :href="'/partner/signup'" role="menuitem">PARTNERS</a>
                </li>
                <li v-if="$isVisible == 1" role="none">
                  <a v-if="isLoggedIn" :href="'/logout'" role="menuitem">LOGOUT</a>
                </li>
                <li class="drawer-space" role="none"></li>
                <li
                  class="drawer-social"
                  v-for="social in settings.social_links.slice().reverse()"
                  :key="social"
                  role="none"
                >
                  <a
                    :href="social.url"
                    :target="social.new_tab ? '_blank' : '_self'"
                    role="menuitem"
                  >
                    {{ social.label }}
                  </a>
                </li>
                <li class="drawer-space" role="none"></li>
                <li class="drawer-social" role="none">
                  <a :href="'mailto:' + settings.contact_email" role="menuitem">
                    {{ settings.contact_email }}
                  </a>
                </li>
                <li class="drawer-social" role="none">
                  <a :href="'tel:' + removeNonDigits(settings.contact_phone)" role="menuitem">
                    {{ settings.contact_phone }}
                  </a>
                </li>
                <li class="drawer-social" role="none">
                  <a
                    :href="'https://www.google.com/maps/place/' + slugify(settings.contact_address)"
                    target="_blank"
                    aria-label="Open address in a new tab"
                    role="menuitem"
                  >
                    {{ settings.contact_address }}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </nav>
      <div class="header-right-details">
        <div class="logo">
          <a :href="'/'">
            <img :src="settings.header_logo" alt="PreGame logo"/>
          </a>
        </div>
        <div class="login">
          <a 
            class="search" 
            @click="toggleSearchBar" 
            role="button" 
            aria-label="Search button"
            tabindex="0"
          >
            SEARCH 
            <img 
              :src="src" 
              alt="Search icon" 
              style="display: inline; margin: 0 5px 0 4px; width: 14px" 
            />
          </a>

          <a
            v-if="!isLoggedIn"
            @click="dropdownHideShow"
            class="cursor-pointer dropdownHideShow"
            role="button"
            aria-haspopup="true"
            :aria-expanded="!isHide ? 'true' : 'false'"
            tabindex="0"
          >
            <div class="user-logged-in-desktop">
              <div class="wave">
                <p style="font-size:14px;">ACCOUNTS</p>
              </div>
              <img
                src="@/assets/images/arrow-down2.svg"
                alt="Arrow down icon"
                class="dropdown-arrow"
              />
            </div>
            <div class="user-logged-in-mobile">
              <div class="wave">
                <img 
                  src="@/assets/images/user.png" 
                  alt="User accounts icon" 
                  class="icon-login-mobile" 
                  style="display: block;"
                />
              </div>
            </div>
            <div v-if="!isHide" class="dropdown">
              <img
                src="@/assets/images/dropdown-before.png"
                class="dropdown-before"
                alt="Dropdown before icon"
              />
              <p>
                <a 
                  :href="'/login'" 
                  class="hidden lg:block"
                  role="link"
                >
                  <img 
                    src="@/assets/images/user.png" 
                    alt="User accounts icon" 
                    class="icon-user"
                  /> 
                  LOG IN 
                </a>
              </p>
              <p>
                <a 
                  :href="'/partner/signup'" 
                  class="hidden lg:block"
                  role="link"
                >
                  <img 
                    src="@/assets/images/partner.png" 
                    alt="Partners icon" 
                    class="icon-partner"
                  /> 
                  PARTNERS
                </a>
              </p>
            </div>
          </a>
          <a
            v-if="isLoggedIn"
            @click="dropdownHideShow"
            class="cursor-pointer dropdownHideShow"
            role="button"
            aria-haspopup="true"
            :aria-expanded="!isHide ? 'true' : 'false'"
            tabindex="0"
          >
            <div class="user-logged-in-desktop">
              <div class="wave">
                <img 
                  src="@/assets/images/wave.png" 
                  :alt="'Hello ' + getName"
                />
                {{ getName }}
              </div>
              <img
                src="@/assets/images/arrow-down2.svg"
                alt="Arrow down icon"
                class="dropdown-arrow"
              />
            </div>
            <div class="user-logged-in-mobile">
              <div class="wave">
                <img 
                  src="@/assets/images/wave.png" 
                  :alt="getNameForMobile"
                />
                <p>{{ getNameForMobile }}</p>
              </div>
            </div>
            <div v-if="!isHide" class="dropdown">
              <img
                src="@/assets/images/dropdown-before.png"
                class="dropdown-before"
                alt="Dropdown before icon"
              />
              <p>
                <a 
                  :href="'/user/profile'"
                  role="link"
                >
                  <img 
                    src="@/assets/images/user.png" 
                    alt="User accounts icon" 
                    class="icon-user"
                  /> 
                  ACCOUNTS
                </a>
              </p>
              <p>
                <a 
                  :href="'/logout'" 
                  class="logout"
                  role="link"
                >
                  <img 
                    src="@/assets/images/logout.png" 
                    alt="Logout icon"
                  /> 
                  LOGOUT
                </a>
              </p>
            </div>
          </a>
          <div 
            class="cursor-pointer header-cart" 
            v-if="!isPartner"
          >
            <a 
              class="cart-count" 
              @click="toggleCartDropdown" 
              role="button" 
              aria-label="Cart button"
              tabindex="0"
            >
              <img
                src="@/assets/images/shopping-cart.svg"
                alt="Shopping cart icon"
              />
              <span>{{ getCartCount() || 0 }}</span>
            </a>
            <CartDropDown
              v-if="isCartDropDown"
              @close-cart-dropdown="toggleCartDropdown"
              aria-live="polite"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isSearch">
      <SearchInput :toggleSearchBar="toggleSearchBar"></SearchInput>
    </div>
  </header>
</template>
<script>
import { useRoute } from "vue-router";
import SearchInput from "../inputs/SearchInput.vue";
import { mapState } from 'vuex';

export default {
  props: {
    items: Array,
    settings: Object,
  },
  data() {
    return {
      src: require("@/assets/images/search.svg"),
      isSearch: false,
      isHide: true,
      isCartDropDown: false,
      isLogin: false,
      Name: "",
      menuVisible: false,
    };
  },
  methods: {
    changeTheme(currentTheme) {
      if (currentTheme === null) {
        currentTheme = "dark";
      }
      document.documentElement.setAttribute("data-theme", currentTheme);
    },
    dropdownHideShow() {
      this.isHide = !this.isHide;
      if (this.isCartDropDown === true) {
        this.isCartDropDown = false;
      }
    },
    toggleSearchBar() {
      this.isSearch = !this.isSearch;
      this.src = this.isSearch
        ? require("@/assets/images/close.svg")
        : require("@/assets/images/search.svg");
    },
    toggleCartDropdown() {
      this.isCartDropDown = !this.isCartDropDown;
      if (this.isHide === false) {
        this.isHide = true;
      }
      if (this.isCartDropDown === true) {
        this.getUserCartFromAccount();
      }
    },
    openDropdown() {
      this.isCartDropDown = true;
      document.addEventListener("click", this.closeCartDropdown);
    },
    isRouteActive(route) {
      const currentRoute = useRoute();
      return currentRoute.path === route;
    },
    openMenu() {
      this.menuVisible = true;
    },
    closeMenu() {
      this.menuVisible = false;
    },
  },
  mounted() {
    this.changeTheme(this.settings.theme);
  },
  computed: {
    isLoggedIn() {
      const getType = this.$store.getters["auth/getGuestLoginUserStatus"];
      if (getType !== "guest") {
        return this.$store.getters["auth/getLoginUserStatus"];
      }

      return null;
    },
    ...mapState({
      userType: state => state.auth.user ? state.auth.user.Type : null // Get user type from Vuex store or null if not present
    }),
    isPartner() {
      return this.userType === 'Partner';
    },
    getName() {
      return this.$store.getters["auth/getLoginUserName"];
    },
    getNameForMobile() {
      const name = this.$store.getters["auth/getLoginUserName"];
      const initials = name
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");

      return initials.toUpperCase(); // Convert to uppercase if needed
    },
    filteredHeaderNav() {
      const items = this.items;
      return items.filter((item) => item.is_header === true && item.disabled !== true);
    },
  },
};
</script>
