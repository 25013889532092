<script setup>
import Header from "@/components/layouts/HeaderView.vue";
import Footer from "@/components/layouts/FooterView.vue";
import {useMenuItems, getGeneralSettings } from "@/utilites/hooks";
</script>

<style lang="scss">
@import '@/assets/sass/main.scss';
</style>


<template>
    <!-- add Skip links for ADA Compliance Implementation  -->
    <a href="#main-content" class="skip-link">Skip to Main Content</a>
    <a href="#main-footer" class="skip-link">Skip to Footer</a>
    <Header 
      v-if="generalSettings.header_logo"
      :items="menuItems"
      :settings="generalSettings"
    />
    <main id="main-content">
      <RouterView />
    </main>
    <Footer id="main-footer" tabindex="-1"
      v-if="generalSettings.footer_logo"
      :items="menuItems"
      :settings="generalSettings"
    />
</template>

<script>

export default {
  name: 'PageLayout',
  data() {
    return {
      menuItems: [],
      generalSettings: [],
    };
  },
  async mounted() {
    const { items} = useMenuItems();
    this.menuItems = items;
    
    const { settings} = getGeneralSettings();
    this.generalSettings = settings;
  }
}

</script>