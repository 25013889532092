<template>
    <div class="container">
      <div class="search-bar">
        <span class="search-bar-heading" v-html="heading" aria-label="Newsletter Subscription Heading"></span>
        <form @submit.prevent="submitNewsletter">
          <input type="text"
            v-model="email"
            placeholder="Enter your email address to subscribe!"
            aria-label="Email Address"
            aria-required="true"
          />
          <button type="submit" class="btn btn-arrow" aria-label="Submit Newsletter Subscription" :disabled="loading">
            <span v-if="loading">Submitting...</span>
            <span v-else>SUBMIT</span>
          </button>
          <alert-messages v-if="errorMessage"  :message="errorMessage" :isError="true" aria-live="assertive" />
          <alert-messages v-if="successMessage" :message="successMessage" :isSuccess="true" aria-live="assertive" />
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  const API_URL = process.env.VUE_APP_API_URL
  export default {
    props: {
      heading: String
    },
    data() {
      return {
        email: '',
        errorMessage: '',
        successMessage: '',
        loading: false
      };
    },
    methods: {
      async submitNewsletter() {
        this.errorMessage = '';
        this.successMessage = '';
        
        if (this.email.trim() === '' || !this.validateEmail(this.email)) {
          this.errorMessage = 'Kindly provide a valid email address to proceed.';
          this.resetErrorFields();
          return;
        }
        
        this.loading = true;
        try {
          const response = await axios.post(API_URL+'newsletter/subscribe', {
            Email: this.email,
            Subscribe: true
          });
  
          // Check if the request was successful
          if (response.status === 200) {
            this.successMessage = 'Congratulations! You\'re now subscribed to our newsletter!';
          } else {
            this.errorMessage = 'We apologize, it seems there was an issue with your newsletter subscription. Please try again later or contact support for assistance.';
          }
        } catch (error) {
          this.errorMessage = 'We apologize, it seems there was an issue with your newsletter subscription. Please try again later or contact support for assistance.';
        } finally {
          this.loading = false;
          this.email = '';
          this.resetErrorFields();
        }
      },
      validateEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      },
      resetErrorFields() {
        setTimeout(() => {
          this.successMessage = '';
          this.errorMessage = '';
        }, 5000)
      }
    }
  };
  </script>
  