<script>
/* eslint-disable */
import { SimpleModal, confirmModal, closeModal } from "@kolirt/vue-modal";
export default {
  name: "Modal",
  props: {
    modalTitle: {
      type: String,
      default: "Default Title",
    },
    modalMessage: {
      type: String,
      default: "Default Message",
    },
    requestType: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    showConfirmModal() {
      confirmModal({
        title: this.modalTitle,
        message: this.modalMessage,
      });
    },
    closeCurrentModal() {
      closeModal();
    },
  },
};
</script>

<template>
  <SimpleModal :title="modalTitle" size="sm" role="dialog" aria-modal="true" :aria-labelledby="modalTitle" class="simple-modal">
    <h6 v-html="modalMessage"></h6>

    <template #footer>
      <button v-if="requestType === false" @click="closeCurrentModal" class="btn btn-danger">No</button>
      <button v-if="requestType === false" @click="showConfirmModal" class="btn btn-primary">Yes</button>
      <button v-if="requestType === true" @click="showConfirmModal" class="btn btn-primary">Remove Item(s)</button>
    </template>
  </SimpleModal>
</template>
