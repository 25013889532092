import paypalService from "@/services/paypal.service";
import stripeService from "@/services/stripe.service";

import axios from "axios";
import moment from "moment-timezone";
const API_URL = process.env.VUE_APP_API_URL;
/**
 * Capitalizes the first letter of a given string.
 *
 * @param {string} string - The input string to be capitalized.
 * @returns {string} The input string with the first letter capitalized, or an empty string if the input is not a string.
 */
export function capitalizeFirstLetter(string) {
  if (typeof string !== 'string') {
    return "";
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Slugifies a given string by converting it to lowercase, 
 * removing special characters, and replacing spaces and underscores with hyphens.
 *
 * @param {string} str - The string to be slugified.
 * @return {string} The slugified string.
 */
export function slugify(str) {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '') // Remove characters that are not word characters, whitespace, or hyphens
    .replace(/[\s_-]+/g, '-') // Replace consecutive whitespace, underscores, or hyphens with a single hyphen
    .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens
}

/**
 * Removes all non-digit characters from a given string.
 *
 * @param {string} str - The input string to remove non-digit characters from.
 * @return {string} - The resulting string with only digit characters.
 */
export function removeNonDigits(str) {
  return str.replace(/\D/g, '');
}

/**
 * Formats the given value as a date in the format "day.month.year".
 *
 * @param {any} value - The value to be formatted as a date.
 * @return {string} The formatted date string.
 */
export function formatDate(value) {
  const date = new Date(value);

  const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits for day
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits for month
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}

export function formatDateTime(value) {
  const date = new Date(value);

  const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits for day
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits for month
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0'); // Ensure two digits for minutes
  const seconds = String(date.getSeconds()).padStart(2, '0'); // Ensure two digits for seconds
  const meridiem = hours < 12 ? 'AM' : 'PM';

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;
  hours = String(hours).padStart(2, '0');
  return `${month}-${day}-${year} ${hours}:${minutes}:${seconds} ${meridiem}`;
}

/**
 * Validates an email address.
 *
 * @param {string} email - The email address to validate.
 * @return {boolean} True if the email is valid, false otherwise.
 */
export function validateEmail(email) {
  /* eslint-disable */
  return email.match(/^[\w -\.]+@([\w -]+\.)+[\w]{2,4}$/);
}

/**
 * Validates a name.
 *
 * @param {string} name - The name to be validated.
 * @return {boolean} True if the name is valid, false otherwise.
 */
export function validateName(name) {
  return /^[a-zA-Z ]{3,20}$/.test(name);
}


/**
 * Validates a password against a specific pattern.
 *
 * @param {string} password - The password to be validated.
 * @return {boolean} Returns true if the password matches the pattern, otherwise false.
 */
export function validatePassword(password) {
  return password.match(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  );
}

/**
 * Splits the given HTML text into an array of individual words.
 *
 * @param {string} htmlText - The HTML text to be split.
 * @return {string[]} An array of individual words.
 */
export function splitWords(htmlText) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlText, 'text/html');

  let result = [];
  doc.body.childNodes.forEach(node => {
    const paragraphNodes = [
      'p',
      'div',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'blockquote',
      'pre',
    ];

    if (paragraphNodes.includes(node.nodeName.toLowerCase())) {
      const text = node.textContent || '';
      result.push(...text.split(' '));
      result.push('\r\n'); // Add a line break marker after each paragraph
    }
  });

  // Remove the last line break marker if it exists
  if (result[result.length - 1] === '\r\n') {
    result.pop();
  }

  return result;
}

/**
 * Replaces the social apple icon.
 *
 * @return {void} 
 */
export function replaceSocialAppleIcon() {
  var appleElements = document.querySelectorAll('.apple');
  if (appleElements.length > 0) {
    var parentElement = appleElements[0].querySelector('div:nth-child(1)');
    if (parentElement) {
      // Do something with the third div element
      var currentElement = parentElement.querySelector('div:nth-child(1)').querySelector('div:nth-child(1)');
      currentElement.style.position = 'static';
      currentElement.style.paddingTop = '2px';
    }
  }
}
/**
 * Parses a JWT token and returns the JSON payload.
 *
 * @param {string} token - The JWT token to parse.
 * @return {object} The JSON payload of the JWT token.
 */
export function parseJwt(token) {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}
/**
 * Checks if a JWT token is valid.
 *
 * @param {string} token - The JWT token to be validated.
 * @return {boolean} Returns true if the token is valid, false otherwise.
 */
export function isJWTValid(token) {
  return token && Date.now() / 1000 < parseJwt(token).exp;
}

/**
 * Validates a phone number.
 *
 * @param {string} phoneNo - The phone number to be validated.
 * @return {boolean} Returns true if the phone number is valid, false otherwise.
 */
export function validatePhoneno(phoneNo) {
  return /^\d{10}$/.test(phoneNo);
}
/**
 * Validates and formats a US phone number.
 *
 * @param {string} phoneNumber - The phone number to be validated and formatted.
 * @return {string} The formatted US phone number in the format (123) 456-7890.
 */
export function isValidFormattedUSPhoneNumber(phoneNumber) {
  if (!phoneNumber) return "";
  // Remove non-digit characters from the phone number
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

  // Extract the area code, prefix, and line number
  const areaCode = cleanedPhoneNumber.substring(0, 3);
  const prefix = cleanedPhoneNumber.substring(3, 6);
  const lineNumber = cleanedPhoneNumber.substring(6);

  // Format the phone number as (123)123-1234
  const formattedPhoneNumber = `(${areaCode}) ${prefix}-${lineNumber}`;

  // If the formatted number matches the original input, it is considered valid
  return formattedPhoneNumber;
}
/**
 * Creates and displays an overlay element on the page if it does not already exist.
 *
 * @return {void} 
 */
export function showOverlay() {
  const loadingDiv = document.createElement('div');
  const loadingOverlay = document.querySelector('.loading-overlay');
  if (!loadingOverlay) {
    loadingDiv.classList.add('loading-overlay');
    loadingDiv.innerHTML = "<div class='loader h-auto max-w-full' id='loader'></div>";
    document.body.appendChild(loadingDiv);
  }
}

/**
 * Hides the overlay after a delay of 1500 milliseconds.
 *
 * @return {void} No return value.
 */
export function hideOverlay() {
  setTimeout(() => {
    const loadingOverlay = document.querySelector('.loading-overlay');
    if (loadingOverlay) {
      loadingOverlay.remove();
    }
  }, 1000);
}

/**
 * Generates a session ID if not present in local storage and stores it along with a guest token.
 *
 */
export async function generateSessionIdAndStore() {
  const user = JSON.parse(localStorage.getItem('user'));
  const guestToken = localStorage.getItem('guestToken');
  if (user === null && !guestToken) {
    const timestamp = Date.now().toString();
    const randomChars = Math.random().toString(36).substring(2, 15);
    const sessionId = timestamp + randomChars;

    localStorage.setItem('sessionId', 'guest-' + sessionId);
    await axios.post(API_URL + 'auth/guest', {
      GuestId: 'guest-' + sessionId
    }).then((response) => {
      if (response.data.StatusCode === 1) {
        localStorage.setItem('guestToken', response.data.Data.AccessToken);

      }
    }).catch((error) => {
      console.log(error);
    });
  }
}

/**
 * Calculates the Stripe fee for the given amount.
 *
 * @param {number} amount - The amount for which to calculate the fee
 * @return {string} The calculated fee amount rounded to two decimal places
 */
export function getStripeFee(amount) {
  // Stripe standard fees: 30 cents + 2.9% of the amount
  const flatFee = 0.30;
  const percentageFee = 0.029;

  // Calculate fees
  const feeAmount = amount - (amount * percentageFee) + flatFee;

  return feeAmount.toFixed(2); // Return the result rounded to two decimal places
}

/**
 * Calculate PayPal charges for a given transaction amount.
 *
 * @param {number} transactionAmount - The amount of the transaction
 * @return {string} The total amount after deducting PayPal charges, rounded to 2 decimal places
 */
export function calculatePayPalCharges(transactionAmount) {
  const percentageFee = 3.49; // PayPal transaction percentage fee
  const fixedFee = 0.49;    // PayPal fixed transaction fee

  // Calculate transaction fee
  const transactionFee = (transactionAmount * (percentageFee / 100)) + fixedFee;

  // Total amount after deducting PayPal charges
  const totalAfterCharges = transactionAmount - transactionFee;

  return totalAfterCharges.toFixed(2);
}

/**
 * Refunds a payment based on the payment method.
 *
 * @param {string} id - The ID of the payment.
 * @param {string} paymentMethod - The payment method used for the payment.
 * @param {number} amount - The amount to refund (default is 0).
 * @return {any} The result of the payment refund.
 */
export function refundPayment(id, paymentMethod, amount = 0) {
  if (paymentMethod.toLowerCase().includes("credit card")) {
    return stripeService.refundPayment(id, amount);
  }
  else if (paymentMethod.toLowerCase().includes("paypal")) {
    return paypalService.refundPayment(id, amount);
  }
}

/**
 * Searches for products that match the given search term in the provided list of products.
 *
 * @param {string} search - The search term to match against products.
 * @param {Array} products - The list of products to search through.
 * @return {Object} An object containing the matching products and their IDs.
 */
export async function searchProducts(search, products) {
  let searchTerm = search.toLowerCase();
  let productId = [];
  const matchingProducts = [];

  for (const product of products) {
    if (
      product.Name.toLowerCase().includes(searchTerm) ||
      product.ShortDescription.toLowerCase().includes(searchTerm) || product.Price == searchTerm
    ) {
      matchingProducts.push(product);
      productId.push(product.ProductId)
      continue;
    }

    for (const color of product.Colors) {
      if (color.Name.toLowerCase().includes(searchTerm)) {
        matchingProducts.push(product);
        productId.push(product.ProductId)
        continue; // Break to avoid adding the same product multiple times
      }
    }

    for (const size of product.Sizes) {
      if (size.Name.toLowerCase().includes(searchTerm)) {
        productId.push(+product.ProductId)
        matchingProducts.push(product);
      }
    }
  }

  return { matchingProducts, productId };
}

/**
 * Generates the image URL based on the CardType provided.
 *
 * @param {string} CardType - The type of card to generate the image URL for.
 * @return {string} The URL of the card image.
 */
export function getImageUrl(CardType) {
  if (['Visa', 'Discover', 'American Express', 'UnionPay', 'MasterCard'].includes(CardType)) { return require(`@/assets/images/${CardType}.svg`); }
  else {
    return require(`@/assets/images/card.svg`);
  }
}

export function getIsVisible() {
  return Number(process.env.VUE_APP_ISVISIBLE);
}

export async function checkImageVideoType(mediaUrl) {
  try {
    const response = await fetch(mediaUrl, { method: 'HEAD' });
    const contentType = response.headers.get('Content-Type');

    // Check if the Content-Type indicates an image or video
    if (contentType && (contentType.startsWith('image/') || contentType.startsWith('video/'))) {
      // Determine the file extension based on the Content-Type
      const extension = contentType.startsWith('image/') ? 'image' : 'video';
      return extension;
    } else {
      // If Content-Type is not found or doesn't indicate image or video
      console.error('Invalid Content-Type:', contentType);
      return 'none'; // Return 'none' if the file type cannot be determined
    }
  } catch (error) {
    console.error('Error detecting file type:', error);
    return 'error'; // Return 'error' if there is an error detecting the file type
  }
}

export function timeAgo(date) {
  return moment.tz(date, "Etc/UTC").fromNow();
}
