import { createRouter, createWebHistory } from 'vue-router';

import store from "/src/store/index.js";
import { isJWTValid } from '@/utilites/helpers';
import { showOverlay } from '@/utilites/helpers';
import { hideOverlay } from '@/utilites/helpers';
import { getIsVisible } from '@/utilites/helpers';


const routes = [
  
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/error/NotFound.vue'),
  },
  {
    path:'/search-result-page',
     name: 'SearchResultPage',
    component: () => import('../views/SearchResultPageView.vue'),
  },
  {
    path: '/product-not-found',
    name: 'ProductNotFound',
    component: () => import('../views/error/ProductNotFound.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicyView.vue')
  },
  {
    path: '/careers',
    name: 'careers',
    component: () => import('../views/JobListView.vue')
  },
  {
    path: '/apply-jobs/:id',
    name: 'applyJobs',
    component: () => import('../views/CareersView.vue'),
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/CompanyView.vue')
  },
  {
    path: '/community',
    name: 'community',
    component: () => import('../views/CommunityView.vue')
  },
  {
    path: '/:pagename',
    name: 'dynamicPages',
    component: () => import('../views/WarmUpView.vue'),
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/HelpView.vue')
  },
  {
    path: '/contact-us',
    name: 'contactus',
    component: () => import('../views/ContactUsView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/terms-of-use',
    name: 'termsofuse',
    component: () => import('../views/TermsServiceView.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/SignupView.vue')
  },
  {
    path: '/partner/signup',
    name: 'partner-signup',
    component: () => import('../views/SignupView.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPasswordView.vue')
  },
  {
    path: '/RecoveryPassword/:email/:token',
    name: 'ResetPassword',
    component: () => import('../views/ResetPasswordView.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/ProductsView.vue')
  },
  {
    path: '/product/:id',
    name: 'product-detail',
    component: () => import('../views/ProductDetailView.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/CartView.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/CheckoutView.vue'),
  },
  {
    path: '/guest-checkout',
    name: 'guestCheckout',
    component: () => import('../views/CheckoutGuestView.vue'),
  },
  {
    path: '/order-confirmation',
    name: 'order-confirmation',
    component: () => import('../views/OrderConfirmationView.vue'),
  },
  {
    path: '/user/profile',
    name: 'profile',
    component: () => import('../views/user/AccountsView.vue')
  },
  {
    path: '/user/order-history',
    name: 'order-history',
    component: () => import('../views/user/AccountsView.vue')
  },
  {
    path: '/user/reviews',
    name: 'reviews',
    component: () => import('../views/user/AccountsView.vue')
  },
  {
    path: '/user/order-detail/:id',
    name: 'order-detail',
    component: () => import('../views/user/AccountsView.vue')
  },
  {
    path: '/user/my-returns',
    name: 'my-returns',
    component: () => import('../views/user/AccountsView.vue')
  },
  {
    path: '/user/my-documents',
    name: 'my-documents',
    component: () => import('../views/user/AccountsView.vue'),
    beforeEnter: (to, from, next) => {
      const userType = store.state.auth.user?.Type;
      if (userType === 'Partner') {
        next();
      } else {
        next('/'); // Redirect to home if not a Partner
      }
    },
  },
  {
    path: '/:id',
    name: 'order-tracking',
    component: () => import('../views/OrderTrackingView.vue')
  },
  {
    path: '/user/addresses',
    name: 'manage-addresses',
    component: () => import('../views/user/AccountsView.vue')
  },
  {
    path: '/user/payment',
    name: 'manage-payment',
    component: () => import('../views/user/AccountsView.vue')
  },
  {
    path: '/VerifyEmail/:id/:token',
    name: "verifyemail",
    component:() => import('../views/EmailVerificationView.vue')
  },
  {
    path: '/shipping-rates',
    name: 'shippingRates',
    component: () => import('../views/ShippingRatesView.vue'),
  },
  {
    path: "/logout",
    name: "logout",
    component: {
      beforeRouteEnter(to, from, next) {
        store.dispatch("auth/logout").then(() => {
          document.body.style.position = "unset";
        });
        localStorage.removeItem("cartItems");
        localStorage.removeItem("orderData");
        localStorage.removeItem("sessionId");
        store.dispatch("cart/resetState")
        next("/login");
      }
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: 'smooth' }; // Scrolls to the top on route change
  }}
);

router.beforeEach((to, from, next) => {
  // Show overlay
  if (to.hash === "") {
    showOverlay()
  }

  if (to.name !== "order-confirmation" && to.name !== "checkout") {
    localStorage.removeItem('orderData');
  }

  const privatePages = ['profile','order-history','manage-addresses','manage-payment','order-detail'];
  const restrictPages = [
    'login',
    'signup',
    'ForgotPassword',
    'ResetPassword',
    'products',
    'product-detail',
    'cart',
    'checkout',
    'guestCheckout',
    'order-confirmation',
    'profile',
    'order-history',
    'order-detail',
    'order-tracking',
    'manage-addresses',
    'manage-payment',
    'verifyemail',
    'shippingRates',
    'help',
    'reviews',
  ];
  if (getIsVisible() === 0 && restrictPages.includes(to.name)) {
    next('/');
  }

  const authRequired = privatePages.includes(to.name);

  const loggedIn = localStorage.getItem('user');
  let user = JSON.parse(loggedIn);
  if (loggedIn && getIsVisible() === 1) {
    if (!isJWTValid(user.AccessToken) ) {
      // token expired
      localStorage.removeItem('user');
      next("/login");
    }
  }

  
  if (authRequired && !loggedIn) {
    next("/login");
  }

  next();

});

router.afterEach(() => {
  hideOverlay()
});

export default router;