export const cart = {
  namespaced: true,
  state: {
    cartItems: JSON.parse(localStorage.getItem('cartItems')) || [],
    itemErrorMessage: null,
    itemSuccessMessage: null,
  },
  actions: {
    addToCart({ commit, state }, item) {
      const existingItem = state.cartItems.find(cartItem => cartItem.itemId === item.itemId);
      if (existingItem) {
        if (item.request && item.request === 'manual') {
          commit('setItemSuccessMessage', null);
          commit('setItemErrorMessage', 'This product with the same attributes is already added to the cart');
        }
      } else {
        if (item.request && item.request === 'manual') {
          commit('setItemSuccessMessage', 'Product added to cart successfully');
          commit('setItemErrorMessage', null);
        }
        commit('addItemToCart', item);
      }

      setTimeout(() => {
        commit('setItemErrorMessage', null);
        commit('setItemSuccessMessage', null);
      }, 5000);
    },
    removeFromCart({ commit }, itemId) {
      commit('removeFromCart', itemId);
    },
    updateQuantity({ commit }, { itemId, quantity }) {
      commit('updateQuantity', { itemId, quantity });
    },
    updateCartItemUserId({ commit, state }, updatedItem) {
      const existingItemIndex = state.cartItems.findIndex(item => item.itemId === updatedItem.itemId);
      if (existingItemIndex !== -1) {
        commit('updateCartItemUserId', { index: existingItemIndex, updatedItem });
      } else {
        commit('addToCart', updatedItem);
      }
    },
    asyncUpdateCartTotal({ commit }, total) {
      commit('updateCartTotal', total);
    },
    resetState({ commit, state }) {
      commit('resetState', state)
    },
  },
  mutations: {
    addItemToCart(state, item) {
      state.cartItems.push({ ...item, quantity: item.quantity });
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    setItemErrorMessage(state, message) {
      state.itemErrorMessage = message;
    },
    setItemSuccessMessage(state, message) {
      state.itemSuccessMessage = message;
    },
    removeFromCart(state, itemId) {
      state.cartItems = state.cartItems.filter(item => item.itemId !== itemId);
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    updateQuantity(state, { itemId, quantity }) {
      const item = state.cartItems.find((cartItem) => cartItem.itemId === itemId);
      if (item) {
        item.quantity = quantity;
        localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      }
    },
    updateCartItemUserId(state, { index, updatedItem }) {
      // Update the existing item at the specified index
      state.cartItems.splice(index, 1, updatedItem);
      // Save to localStorage or perform other persistence logic
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    updateCartTotal(state, total) {
      state.cartTotal = total;
    },
    resetState(state) {
      state.cartItems = [];
      state.cartTotal = 0;
      state.cartCount = 0;
    },
  },
  getters: {
    cartTotal: (state) => state.cartItems.reduce((total, item) => (item.inStock) ? total + item.quantity * item.price : total, 0),
    cartTaxAmount: (state) => state.cartItems.reduce((totalTax, item) => item.inStock ? totalTax + item.quantity * item.taxAmount : totalTax, 0),
    cartCount: (state) => state.cartItems.length,
    cartTotalQuantity: (state) => state.cartItems.reduce((totalQuantity, item) => totalQuantity + item.quantity, 0),
    getTaxTypeAndValue(state) {
      if (state.cartItems && state.cartItems.length > 0) {
        const hasPercentage = state.cartItems.some(item => item.IsTaxFixed === true);
        const taxValue = state.cartItems.reduce((totalTax, item) => totalTax = item.taxPercentage, 0);

        return {
          taxType: hasPercentage,
          taxValue: taxValue
        }
      }
    },
    getDiscountedValue: (state) => state.cartItems.reduce((totalDiscount, item) => totalDiscount + item.discounted, 0)
  },
};