import gsap from 'gsap';

export default {
    methods: {
        /**
         * Animates the text by changing the opacity, color, and stagger effect of the elements.
         *
         * @param {type} None
         * @return {type} None
         */
        animateText(className) {
            if (!className) {
                className = 'word';
            }
            const elements = document.querySelectorAll('.animation');
            elements.forEach(element => {
                const words = element.querySelectorAll('.' + className);
                gsap.to(words, {
                    opacity: 1,
                    stagger: 0.3,
                    duration: 1.5,
                    repeat: -1,
                    onStart: () => {
                        gsap.set(words, { opacity: 0.5 });
                    }
                });
            });
        }
    }
}
