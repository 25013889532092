import { butterCMS } from "@/utilites/ButterCMS";
import { reactive, ref } from "vue";
import axios from "axios";
import { checkImageVideoType } from "@/utilites/helpers";

const state = reactive({
  error: null,
});

/**
 * Generates an array of menu items by retrieving navigation menu data from the butterCMS API.
 *
 * @return {Object} The generated array of menu items.
 */
export const useMenuItems = () => {
  const items = ref([]);
  butterCMS?.content
    .retrieve(["navigation_menu"], { order: 'display_order' })
    .then((response) => {
      items.value = response.data.data.navigation_menu;
    })
    .catch((e) => (state.error = e))
    .finally();

  return { items};
};

/**
 * Retrieves the general settings from the butterCMS content and returns them.
 *
 * @return {Object} The general settings.
 */
export const getGeneralSettings = () => {
  const settings = ref([]);
  butterCMS?.content
    .retrieve(["general_settings"])
    .then((response) => {
      settings.value = response.data.data.general_settings[0];
    })
    
    .catch((e) => (state.error = e))
    .finally();

    return { settings };
};

/**
 * Retrieves the data for the home page.
 *
 * @param {string} page - The page to retrieve data for.
 * @return {Promise<object>} The data for the home page.
 */
export const getHomePageData = (page) => {

  return butterCMS.page
    .retrieve("*", page)
    .then((response) => {
      const pageData = response.data.data.fields;
      const heroData = pageData.hero_section[0].fields || [];

      const imageTypePromise = (heroData.image.length > 0) ?
      checkImageVideoType(heroData.image) :  Promise.resolve(null);
      
      return imageTypePromise.then((imageType) => {
        const heroSectionData = {
          image: (heroData.image.length > 0) ? heroData.image : null,
          altText: heroData.alt_text || "",
          heading: heroData.heading || "",
          description: heroData.description || "",
          buttonText: heroData.button_text || "",
          buttonAction: heroData.button_action || "",
          imageType: imageType,
        }

        const seoData = pageData.seo || [];
        const aboutCompany = {
          heading: pageData.about_company_heading || "",
          description: pageData.about_company_content || "",
          buildHeading: pageData.build_product_heading || "",
          buildAction: pageData.build_product_action || "",
        }
        const timelineSlider = pageData.image_carousel;
        const productData = {
          heading: pageData.product_headings || "",
          button_text: pageData.product_button_text || "",
          button_action: pageData.product_button_action || "",
        }
        const insiderSection = {
          fold: pageData.insider_section || [],
          scrollText: pageData.scroll_text || ""
        }
        const mobileSlider = pageData.mobile_slider[0].fields || [];
        
        const eventsData = {
          allEvents: pageData.all_events || [],
          buttonText: pageData.all_event_button_text || "All Events"
        }   
        
        const faqData = {
          heading: pageData.faq_heading || "",
          content: pageData.faq_content || "",
          faqs: pageData.faqs || [],
        }

        return {
          heroSectionData,
          aboutCompany,
          timelineSlider,
          productData,
          insiderSection,
          mobileSlider,
          eventsData,
          faqData,
          seoData,
        };
      });

    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

/**
 * Retrieves the privacy page data from the butterCMS API.
 *
 * @param {string} page - The page to retrieve data for.
 * @return {Promise} A Promise that resolves to an object containing the page data,
 *         SEO data, and the date the page was last updated.
 */
export const getPrivacyPageData = (page) => {

  return butterCMS.page
    .retrieve("*", page)
    .then((response) => {
      const pageData = response.data.data.fields;
      const seoData = pageData.seo || [];
      const updatedDate = response.data.data.updated || {};

      return {
        pageData,
        seoData,
        updatedDate
      };
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

/**
 * Retrieves the product list page data for a given page.
 *
 * @param {number} page - The page number to retrieve the data for.
 * @return {Promise<Object>} A Promise that resolves with an object containing the page data and SEO data.
 */
export const getProductListPageData = (page) => {

  return butterCMS.page
    .retrieve("*", page)
    .then((response) => {
      const pageData = response.data.data.fields;
      const seoData = pageData.seo || [];

      return {
        pageData,
        seoData
      };
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

/**
 * Retrieves company page data from the ButterCMS API.
 *
 * @param {string} page - The name of the page to retrieve data for.
 * @return {Promise} A Promise that resolves to an object containing the company page data.
 */
export const getCompanyPageData = async (page) => {
  try {
    return butterCMS.page
      .retrieve("*", page)
      .then((response) => {
        const pageData = response.data.data.fields;

        const imageTypePromise = (pageData.content_fold.image.length > 0) ? checkImageVideoType(pageData.content_fold.image) : Promise.resolve(null);
        return imageTypePromise.then((imageType) => {
          const heroFold = {
            image: (pageData.content_fold.image.length > 0) ? pageData.content_fold.image : null,
            alt_text: pageData.content_fold.alt_text || "",
            heading: pageData.content_fold.heading || "",
            description: pageData.content_fold.description || "",
            button_text: pageData.content_fold.button_text || "",
            button_action: pageData.content_fold.button_action || "",
            imageType: imageType, // Assign the resolved value of checkImageVideoType
          };
          const seoData = pageData.seo || [];
          const partners = { 
            heading: pageData.partner_heading || "",
            partners: pageData.partners || {},
          }
          const teams = { 
            heading: pageData.team_heading || "",
            description: pageData.team_description || "",
            teams: pageData.teams || {},
          }
          const missionFold = {
            featureImage: pageData.mission_image || "",
            featureImageAltText: pageData.alt_text || "",
            content: pageData.mission || "",
          };
          const commMissionFold = {
            featureImage: pageData.mission_image || "",
            featureImageAltText: pageData.alt_text || "",
            content: pageData.mission || "",
          };
          const workPGFold = {
            left_heading: pageData.workpg_left_heading || "",
            left_description: pageData.workpg_left_text || "",
            left_email: pageData.workpg_left_email || "",
            right_heading: pageData.workpg_right_heading || "",
            right_description: pageData.workpg_right_content || "",
            right_email: pageData.workpg_right_email || "",
          };

          const values = pageData.value_text || "";
          const bottomSlider = pageData.bottom_slider || "";
          const instagramFeed = {
            follow: pageData.instagram_follow.instagram_follow || "",
            text: pageData.instagram_follow.instagram_text || "",
            url: pageData.instagram_follow.instagram_url.url || "",
          }
          
          return {
            heroFold,
            seoData,
            partners,
            teams,
            missionFold,
            values,
            workPGFold,
            commMissionFold,
            instagramFeed,
            bottomSlider
          };
        });
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  } catch (error) {
    console.error(error);
    throw error;
  }
};


/**
 * Retrieves the product detail data from the butterCMS API.
 *
 * @param {string} pageId - The ID of the page to retrieve the data for.
 * @return {Promise<object>} A promise that resolves with an object containing the retrieved data.
 */
export const getProductDetailData = (pageId) => {

  return axios.get(
    process.env.VUE_APP_BUTTER_CMS_API_URL+'pages/product_detail/?auth_token='+process.env.VUE_APP_BUTTER_CMS_API_KEY+'&fields.product_id='+pageId)
    .then((response) => {
      if (response.data.data.length >0) {
        const pageData = response.data.data[0].fields;
        const seoData = pageData.seo || [];
        const productInfo = {
          id: pageData.product_id.toFixed(0) || "",
          heading: pageData.heading || "",
          description: pageData.description || "",
          manuals: pageData.manuals || [],
        }
        const productContent = pageData.contents || [];
        const timelineSlider = pageData.timeline_slider || [];
  
        const instagramFeed = {
          follow: pageData.instagram.instagram_follow || "",
          text: pageData.instagram.instagram_text || "",
          url: pageData.instagram.instagram_url.url || "",
        }
  
        return {
          seoData,
          productInfo,
          productContent,
          timelineSlider,
          instagramFeed
        };
      }
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};


/**
 * Retrieves user account page data from butterCMS.
 *
 * @return {Promise} A promise that resolves to an object containing the SEO data and content folds.
 */
export const getUserAccountPageData = () => {

  return butterCMS.page
    .retrieve("*", "user-accounts")
    .then((response) => {
      const pageData = response.data.data.fields;
      const seoData = pageData.seo || [];
      const contentFolds = pageData.content_folds || [];

      return {
        seoData,
        contentFolds,
      };
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

/**
 * Retrieves the help page data from butterCMS.
 *
 * @return {Promise} A promise that resolves to an object containing the SEO data and content folds.
 */
export const getHelpPageData = () => {

  return butterCMS.page
    .retrieve("*", "help")
    .then((response) => {
      const pageData = response.data.data.fields;
      const seoData = pageData.seo || [];

      return {
        seoData,
        pageData
      };
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

/**
 * Retrieves contact page data from butterCMS and returns the SEO data and page data.
 *
 * @param None
 * @return {Object} An object containing the SEO data and page data
 */
export const getContactPageData = (page) => {

  return butterCMS.page
    .retrieve("*", page)
    .then((response) => {
      const pageData = response.data.data.fields;
      const seoData = pageData.seo || [];

      return {
        seoData,
        pageData
      };
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const getSearchData = (terms) => {
  return butterCMS.page
    .search(terms)
    .then((response) => {
      const pageData = response.data.data;
      return {
        pageData
      };
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const checkPageExists = async (pageName) => {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_BUTTER_CMS_API_URL}pages/*/${pageName}/?auth_token=${process.env.VUE_APP_BUTTER_CMS_API_KEY}`
    );
    if (response.data.data.page_type.toLowerCase() !== "company") {
      return 404;
    }
    // If the request succeeds, return true (the page exists)
    return response.status;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    }
  }
}
