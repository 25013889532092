<script setup>
import NewsletterForm from "@/components/layouts/NewsletterForm.vue";
import { removeNonDigits, slugify } from '@/utilites/helpers';
</script>

<template>
    <footer>
      <NewsletterForm :heading="settings.newsletter_heading" />
      <div class="container">
        <div class="footer-wrapper">
          <div class="main-column grid grid-cols-2 w-full">
            <div class="footer-logo">
              <a :href="'/'"
                ><img :src="settings.footer_logo" alt="Pregame"/></a>
            </div>
            <div class="right-column grid grid-cols-3">
              <div class="footer-menu">
                <nav>
                  <ul>
                    <li v-for="item in filteredFooterNav" :key="item.id">
                      <a 
                      :href="item.url" 
                      :target="item.new_tab ? '_blank' : '_self'"
                      :class="{ active: isRouteActive(item.url) }"
                      aria-label="Open in a new tab"
                      >
                        {{ item.label.toUpperCase() }}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="footer-address">
                <p>
                  <a :href="'mailto:'+settings.contact_email">{{ settings.contact_email }}</a>
                </p>
                <p>
                  <a :href="'tel:'+removeNonDigits(settings.contact_phone)">{{ settings.contact_phone }} </a>
                </p>
                <p class="max-w-[200px]">
                  <a :href="'https://www.google.com/maps/place/'+slugify(settings.contact_address)" target="_blank" aria-label="Open in a new tab">{{ settings.contact_address }}</a>
                </p>
              </div>
              <div class="footer-social">
                <p v-for="social in settings.social_links.slice().reverse()" :key="social">
                  <a :href="social.url" :target="social.new_tab ? '_blank' : '_self'" aria-label="Open in a new tab">{{ social.label }}</a>
                </p>
              </div>
            </div>
            <div class="app-store">
              <div class="app-image">
                <a :href="settings.ios_store_url" target="_blank" aria-label="Open in a new tab">
                  <img :src="settings.ios_store_button" alt="Apple Store | PreGame" />
                </a>
                <a :href="settings.android_store_url" target="_blank" aria-label="Open in a new tab">
                  <img :src="settings.android_store_button" alt="Android Store | PreGame" />
                </a>
              </div>
              <div class="visa-card-image">
                <img :src="settings.apple_pay_icon" alt="Apple Pay | PreGame" />
                <img :src="settings.mastercard_icon" alt="Mastercard | PreGame" />
                <img :src="settings.visa_icon" alt="Visa | PreGame" />
                <img src="@/assets/images/amex.svg" alt="Amex | PreGame" />
              </div>
            </div>
          </div>
          <div class="footer-bottom grid grid-cols-2">
            <p class="footer-text">{{ settings.reserve_content }}</p>
            <div class="grid grid-cols-2">
              <div class="footer-privacy-policy">
                <p v-for="item in filteredTermsNav" :key="item.id">
                  <router-link 
                    :to="item.url" 
                    :class="{ 'active': isRouteActive(item.url) }"
                    :target="item.new_tab ? '_blank' : '_self'"
                    aria-label="Open in a new tab">
                    {{ item.label.toUpperCase() }}
                  </router-link>
                </p>
              </div>
              <div class="copy-right" v-if="settings.powered_by != null">
                <p class="powered-by">by <a :href="'#'" role="link" aria-label="Link to powered by information">{{ settings.powered_by }}</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  
</template>
<script>
import { useRoute } from 'vue-router';
export default {
  props: {
    items: Array,
    settings: Object,
  },
  computed: {
    filteredTermsNav() {
      const items = this.items;
      return items.filter(item => item.terms_page !== false);
    },
    filteredFooterNav() {
      const items = this.items;
      return items.filter(item => item.terms_page !== true && item.disabled !== true);
    }
  },
  methods: {
    isRouteActive(route) {
      const currentRoute = useRoute();
      return currentRoute.path === route;
    }
  }
};
</script>