export const checkout = {
  namespaced: true,
  state: {
    checkoutData: {
      userData: null,
      addressData: null,
      deliveryData: null,
      paymentData: null,
      orderDetail: null,
      setting: null,
      promoCode: null,
      promoError: null
    }
  },
  actions: {
    submitData({ commit }, { key, data }) {
      commit('setCheckoutData', { key, data });
    },
    resetCheckoutState({commit, state}) {
      commit('resetCheckoutState', state)
    },

  },
  mutations: {
    setCheckoutData(state, { key, data }) {
      state.checkoutData[key] = data;
    },
    resetCheckoutState(state) {
      state.checkoutData = {
        userData: null,
        addressData: null,
        deliveryData: null,
        paymentData: null,
        orderDetail: null,
        setting: null,
        promoCode: null
      };
    },
  },
  getters: {
    getCheckoutData(state) {
      return state.checkoutData
    },
    getDeliveryData(state) {
      return state.checkoutData.deliveryData
    },
    getDeliveryPrice(state) {
      if (state.checkoutData.deliveryData === null) {
        return 0
      }
      return state.checkoutData.deliveryData.price;
    },
    getAddressData(state) {
      return state.checkoutData.addressData
    },
    getShippingSetting(state) {
      return state.checkoutData.setting
    },
    getPaymentData(state) {
      return state.checkoutData.paymentData
    },
    getPromoApplied(state) {
      return state.checkoutData.promoCode
    },

    getPromoError(state) {
      return state.checkoutData.promoError
    }
  }
};